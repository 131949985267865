import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512.000000 512.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path d="M2170 3180 c0 -90 -3 -110 -15 -110 -11 0 -15 19 -17 88 -2 69 -6 87
-18 87 -11 0 -15 -16 -17 -75 -1 -41 -2 -81 -3 -87 0 -7 -4 -13 -10 -13 -6 0
-10 28 -10 65 0 58 -2 65 -20 65 -18 0 -20 -7 -20 -65 0 -51 -3 -65 -15 -65
-11 0 -15 11 -15 41 0 33 -3 40 -17 37 -13 -2 -18 -13 -18 -38 0 -32 -3 -35
-34 -40 -33 -5 -34 -6 -34 -50 0 -38 3 -45 21 -48 21 -3 22 -8 22 -147 0 -140
-1 -144 -22 -147 -18 -2 -21 -10 -21 -43 l0 -40 126 -3 127 -3 0 46 c0 45 0
45 -36 45 l-35 0 3 43 3 42 63 3 62 3 0 44 0 45 -65 0 -65 0 0 55 0 55 49 0
c47 0 50 -2 53 -27 3 -26 6 -28 56 -31 31 -2 60 2 68 9 8 6 14 7 14 3 0 -5
-31 -92 -70 -193 -38 -102 -70 -193 -70 -203 0 -17 12 -18 165 -18 l165 0 0
-56 c0 -93 1 -94 143 -94 108 0 128 3 167 23 57 28 76 57 96 139 33 133 -2
253 -86 296 -39 19 -60 22 -168 22 -114 0 -124 1 -117 18 24 59 36 104 31 117
-4 12 -30 15 -135 15 l-130 0 -3 38 -3 37 -52 3 -53 3 0 110 c0 102 -1 109
-20 109 -19 0 -20 -7 -20 -110z m670 -418 c54 -54 63 -196 19 -288 -19 -40
-61 -70 -80 -59 -5 4 -9 88 -9 191 0 180 0 184 21 184 11 0 33 -13 49 -28z
m-322 -187 c-2 -1 -18 -5 -35 -8 -25 -5 -33 -3 -33 8 0 8 15 53 33 101 l32 86
3 -92 c1 -51 1 -94 0 -95z"/>
					<path d="M3025 2462 c-15 -4 -11 -8 19 -17 49 -14 55 -29 21 -51 -52 -34 -315
-108 -665 -187 -64 -14 -62 -15 158 -16 l222 -1 93 36 c182 72 307 148 307
189 0 27 -105 59 -155 47z"/>
					<path d="M1400 1995 c0 -88 1 -95 20 -95 17 0 20 7 20 40 0 39 1 40 36 40 28
0 35 3 32 18 -2 11 -14 18 -36 20 -25 3 -32 8 -32 24 0 18 5 20 45 16 38 -3
45 0 45 14 0 15 -10 18 -65 18 l-65 0 0 -95z"/>
					<path d="M1613 2073 c-3 -10 -19 -51 -35 -91 -15 -40 -28 -75 -28 -77 0 -12
39 -3 44 10 3 10 19 15 46 15 27 0 43 -5 46 -15 4 -8 17 -15 31 -15 14 0 23 3
21 8 -1 4 -19 47 -38 95 -30 74 -38 87 -58 87 -13 0 -25 -8 -29 -17z m46 -90
c1 -7 -8 -13 -19 -13 -23 0 -25 12 -8 43 11 21 11 21 19 2 4 -11 8 -26 8 -32z"/>
					<path d="M1760 2069 c0 -16 5 -20 28 -17 l27 3 3 -77 c3 -69 5 -78 22 -78 17
0 19 9 22 78 3 77 3 77 31 76 20 -1 27 3 27 17 0 17 -9 19 -80 19 -74 0 -80
-1 -80 -21z"/>
					<path d="M1950 1995 c0 -88 1 -95 20 -95 19 0 20 7 20 95 0 88 -1 95 -20 95
-19 0 -20 -7 -20 -95z"/>
					<path d="M2030 2069 c0 -17 6 -20 33 -19 17 1 35 2 39 1 4 -1 -10 -30 -32 -66
-22 -36 -40 -70 -40 -75 0 -6 33 -10 76 -10 66 0 75 2 72 18 -2 12 -14 18 -41
20 -42 4 -45 9 -19 43 20 26 62 97 62 105 0 2 -34 4 -75 4 -69 0 -75 -2 -75
-21z"/>
					<path d="M2274 2073 c-4 -10 -19 -51 -35 -92 -16 -40 -29 -75 -29 -77 0 -12
39 -2 44 11 4 10 20 15 51 15 33 0 45 -4 45 -15 0 -9 9 -15 25 -15 l25 0 -21
53 c-12 28 -28 71 -37 95 -13 33 -21 42 -39 42 -14 0 -26 -8 -29 -17z m34
-101 c-27 -5 -30 1 -17 34 l11 29 13 -30 c12 -27 12 -30 -7 -33z"/>
					<path d="M2430 1995 c0 -76 3 -95 14 -95 14 0 29 28 25 46 -8 48 25 44 56 -6
15 -24 33 -40 44 -40 19 0 19 2 -2 39 -21 37 -21 40 -4 58 19 22 22 46 7 74
-9 16 -22 19 -75 19 l-65 0 0 -95z m96 55 c8 0 14 -7 14 -15 0 -10 -10 -15
-33 -15 -19 0 -37 3 -40 7 -11 10 15 34 30 28 8 -3 21 -5 29 -5z"/>
					<path d="M2638 1997 c-31 -79 -34 -93 -21 -96 9 -2 22 4 28 13 15 20 78 21 95
1 15 -18 54 -20 47 -2 -85 218 -94 223 -149 84z m77 -4 c5 -20 3 -23 -19 -23
-24 0 -24 2 -14 31 11 32 21 29 33 -8z"/>
					<path d="M2910 1995 l0 -95 55 0 c74 0 105 28 105 95 0 67 -31 95 -105 95
l-55 0 0 -95z m108 43 c17 -17 15 -64 -4 -82 -8 -9 -26 -16 -40 -16 -22 0 -24
3 -24 55 0 54 1 55 28 55 15 0 33 -5 40 -12z"/>
					<path d="M3142 2010 c-17 -44 -32 -87 -32 -95 0 -19 29 -20 36 0 4 10 20 15
50 15 30 0 46 -5 50 -15 4 -8 15 -15 26 -15 15 0 18 4 13 18 -4 9 -20 52 -37
95 -24 63 -34 77 -51 77 -18 0 -28 -15 -55 -80z m78 -27 c0 -7 -11 -13 -24
-13 -23 0 -24 2 -13 30 11 28 13 29 24 13 7 -9 13 -23 13 -30z"/>
					<path d="M3312 2073 c2 -11 14 -19 30 -21 26 -3 27 -5 30 -75 3 -64 5 -72 23
-72 18 0 20 8 23 73 2 54 6 72 17 73 8 1 21 2 27 3 7 0 14 9 16 19 3 15 -6 17
-83 17 -77 0 -86 -2 -83 -17z"/>
					<path d="M3549 2043 c-26 -59 -49 -123 -49 -134 0 -15 38 -10 44 6 3 10 19 15
46 15 27 0 43 -5 46 -15 6 -15 44 -21 44 -7 0 5 -15 46 -34 93 -26 64 -39 85
-55 87 -17 3 -25 -6 -42 -45z m55 -39 c10 -26 7 -34 -14 -34 -11 0 -20 4 -20
9 0 17 12 41 20 41 4 0 11 -7 14 -16z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
